import React, { useEffect, useState } from "react";
import { env } from "../../env";
import { NavLink, useNavigate } from "react-router-dom";
import PropertyGallerySlider from "../pages/property/PropertyGallerySlider";
import PropertyCardLgSkeleton from "../skeleton/PropertyCardLgSkeleton";
import { AppDownloadPopupToggleAction } from "../../redux-service/action/action";
import { useDispatch } from "react-redux";

export default function PropertyCardLg(props) {
  const navigate = useNavigate();
  const [property, setProperty] = useState();

  useEffect(() => {
    setTimeout(() => {
      setProperty(props.property);
    }, 300);
  }, [props.property]);

  const dispatch = useDispatch();

  function OpenAppDownloadPopUp() {
    dispatch(AppDownloadPopupToggleAction(true));
  }

  if (!property) {
    return <PropertyCardLgSkeleton />;
  }

  return (
    <>
      <div className="property-card-lg" onClick={() => navigate(`/properties${property?.web_link}`)}>
        <div className="property-card-image">
          <PropertyGallerySlider
            image={property?.image}
            gallery={property?.gallery}
          />
          <div className="property-card-image-head-top">
            <span className="property-card-image-tag">
              <i class="fa-solid fa-check"></i>&nbsp;TruCheck<sup>TM</sup>
            </span>
            <span className="property-card-image-tag">
              {property?.completionStatus}
            </span>
          </div>
          <div className="property-card-image-head-bottom-left">
            <button className="property-card-image-btn">
              <i className="bi bi-columns"></i>
            </button>
            <button className="property-card-image-btn">
              <i className="fa-regular fa-circle-play"></i>
            </button>
          </div>

          <div className="property-card-image-head-bottom-right">
            <button className="property-card-image-btn">
              <i className="fa-regular fa-heart"></i>
            </button>
          </div>
        </div>
        <div className="property-card-content">
          <div className="property-info">
            <h3 className="card-price mb-2">
              <span>{property?.currency?.currency && property?.currency?.currency}</span> {property?.actual_price}
            </h3>
            <ul className="property-details ul-none">
              <li>{property?.property_type}</li>
              <li>
                <ul className="ul-none property-details-2">
                  <li>
                    <img
                      src={`${env.imageBaseURL}/bed-icon.png`}
                      alt=""
                      className="img-fluid"
                    />
                    <b>{property?.bedrooms}</b>
                  </li>
                  <li>
                    <img
                      src={`${env.imageBaseURL}/bath-icon.png`}
                      alt=""
                      className="img-fluid"
                    />
                    <b>{property?.bathrooms}</b>
                  </li>
                </ul>
              </li>
              <li>
                Area: {property?.total_unit_size?.size}{" "}
                {property?.total_unit_size?.type}
              </li>
            </ul>
            <p className="card-description">
              {/* Golf Course View | 60% Paid | Exclusive */}
              {property?.description && property.description.length > 20
                ? `${property.description.substring(0, 50)}...`
                : property.description}
            </p>
            {property?.address && (
              <p className="card-location">
                <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;
                {property?.address}
              </p>
            )}
            <p>
              <span className="card-authenticity">
                <i className="fa-solid fa-star"></i> Property authenticity was
                validated on 8th of January
              </span>
            </p>
          </div>
          <ul className="property-contact ul-none mt-3">
            <li className="contact-btn">
              <NavLink to={`to:${property?.agent?.call}`}>
                <i className="fas fa-phone" style={{ fontSize: "16px" }}></i>{" "}
                Call
              </NavLink>
            </li>
            <li className="contact-btn">
              <NavLink to={`mailto:${property?.agent?.email}`}>
                <i className="fas fa-envelope" style={{ fontSize: "19px" }}></i>{" "}
                Email
              </NavLink>
            </li>
            <li className="contact-btn whatsapp">
              <a
                href={`https://wa.me/${property?.agent?.whatsApp}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp" style={{ fontSize: "24px" }}></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <span className="card-footer-tag">Signature</span>
          {property?.agent?.agency?.agencyLogo && (
            <img
              src={`${env.serverURL}${property?.agent?.agency?.agencyLogo}`}
              alt=""
              className="img-fluid card-footer-logo"
            />
          )}
        </div>
      </div>
    </>
  );
}
